import { MenuItem } from './menu.model';
import { MenuService } from '../../../services/menu.service';

export const MENU: MenuItem[] = [
  {
    label: 'Inicio',
    isTitle: true
  },
  {
    label: 'Panel de control',
    icon: 'far fa-tachometer',
    link: '/dashboard',
  },
  {
    label: 'Usuario',
    isTitle: true
  },
  {
    label: 'Mi perfil',
    icon: 'fas fa-user',
    link: '/profile',
  },
  {
    label: 'Cerrar sesión',
    icon: 'fas fa-sign-out-alt',
    action: 'signout',
  },
  // {
  //   label: 'Recursos',
  //   isTitle: true
  // },
  // {
  //   label: 'Mis recursos',
  //   icon: 'fas fa-key',
  //   link: '/resources',
  // },
  // {
  //   label: 'Documentos',
  //   isTitle: true
  // },
  // {
  //   label: 'Mis documentos',
  //   icon: 'fas fa-folder',
  //   link: '/userDocuments',
  //   badge: {
  //     text: '',
  //     variant: 'danger'
  //   }
  // },
  // {
  //   label: 'Proyectos',
  //   isTitle: true
  // },
  // {
  //   label: 'Proyectos',
  //   icon: 'fas fa-tasks',
  //   link: '/projects',
  // },
  // {
  //   label: 'Versiones',
  //   icon: 'far fa-code-merge',
  //   link: '/releases',
  // },
  // {
  //   label: 'Muro de ideas',
  //   isTitle: true,
  // },
  // {
  //   label: 'Ideas',
  //   icon: 'fas fa-lightbulb',
  //   link: '/ideas',
  // },
  {
    label: 'Administración',
    isTitle: true,
    hr: true
  },
  {
    label: 'Usuarios',
    icon: 'fas fa-users',
    link: '/users',
  },
  // {
  //   label: 'Recursos',
  //   icon: 'fas fa-key',
  //   link: '/adminResources'
  // }
];
