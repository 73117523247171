// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.8',
  // apiUrl: 'http://192.168.1.111:3200',
  // apiUrl: 'http://localhost:3200',
  apiUrl: 'https://demo-api.hanna.cloud',
  localStorageFlagName: 'hana_ks88dgblmm',
  monarApp: 'Hanna-Web',
  monarAppId: '13',
  monarConsole: '1',
  currencySimbol: '€',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  dateFormat: 'DD/MM/YYYY',
  timeFormat: 'HH:mm:ss',
  dateCommentsFormat: 'DD-MM-YYYY',
  timeCommentsFormat: 'HH:mm',
  google_client_id: '206829272031-63pf0ellvc1cs6q48k99ohc68vaelqog.apps.googleusercontent.com',
  google_client_secret: 'pxXmmTBUJKv-lguLQQl_ONaa',
  iconsPath: '/assets/icons'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
